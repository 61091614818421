<template xmlns:mailto="http://www.w3.org/1999/xhtml">
  <div
    class="alert alert-calendar col-md-8"
    style="font-size: 18px; text-align: center; width: 100%"
  >
    <span
      class="pull-right"
      style="margin-left: 15px; color: black; cursor: pointer; transition: 0.7s"
      onclick="this.parentElement.style.display='none';"
    >
      &times;
    </span>
    <div style="text-align: left; padding-left: 15px;">
      <h3 style="text-transform: none">Cześć!</h3>
      Poniżej znajdziesz harmonogram dostaw oraz godziny pracy Biura Obsługi Klienta w okresie świątecznym:<br>
      <b>Harmonogram dostaw:</b><br>
      <ul style="list-style: inside">
        <li><b>24 grudnia:</b> dostawy realizujemy standardowo</li>
        <li><b>25 grudnia:</b> posiłki na ten dzień dostarczymy <b>24 grudnia</b></li>
        <li><b>26 grudnia oraz 1 stycznia:</b> w te dni <b>nie realizujemy dostaw</b></li>
      </ul>
      <b>Godziny pracy Biura Obsługi Klienta:</b><br>
      <ul style="list-style: inside">
        <li><b>24.12</b> – 06:00-12:00</li>
        <li><b>25-26.12</b> – nieczynne</li>
        <li><b>31.12</b> – 06:00-12:00</li>
        <li><b>1.01</b> – nieczynne</li>
      </ul>
      W pozostałe dni godziny pracy BOK pozostają bez zmian<br>
      Życzymy spokojnego czasu i pomyślności w nadchodzącym Nowym Roku!! 🎄✨<br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NtfyMessageTemporary'
}
</script>
